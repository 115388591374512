body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  color: white;
  height: 100vh;
  width: 100vw;
}

@media (max-device-width: 1415px) {
  @media (orientation: landscape) {
    body {
      overflow: scroll;
    }
  }
}

.App {
  text-align: center;
}

.MuiListItem-gutters {
  margin: 0;
}

.MuiButtonBase-root {
  height: 100%;
  border-radius: 0 !important;
}

.MuiList-padding {
  padding: 0;
  background: transparent !important;
}

li {
  margin-bottom: 10px;
}

.MuiButton-label {
  font-family: "Microsoft Sans Serif" !important;
  font-size: 16px !important;
  text-transform: none !important;
}

@supports (-webkit-touch-callout: none) {
  .safari-flex-fix {
    height: -webkit-fill-available;
  }
}

.container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}
